<template>
  <v-container>
    <v-dialog v-model="currentUser.dialog" max-width="500px">
      <v-card>
        <v-card-title>Guthaben bearbeiten für {{ currentUser.name }}</v-card-title>
        <v-divider class="mt-2 mb-5"></v-divider>
        <v-card-text>
          <v-text-field type="number" outlined label="Einzahlung" v-model="currentUser.kiosk_guthaben_einzahlung"></v-text-field>
          <v-text-field type="number" outlined label="Aktuelles Guthaben" v-model="currentUser.kiosk_guthaben_akutell"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="currentUser.dialog = false">Abbrechen</v-btn>
          <v-btn color="primary" @click="saveUser(currentUser)">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-text-field
      v-model="search"
      placeholder="Suche deinen Namen"
      outlined
      class="mt-5"
    ></v-text-field>
    <v-list nav>
      <v-list-item-group>
        <v-list-item v-for="(item, i) in filteredUsers" :key="i">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action-text>
            <v-btn elevation="0" class="mx-2" small @click="currentUser.name = item.name, currentUser.id = item.id, currentUser.kiosk_guthaben_akutell = item.kiosk_guthaben_akutell, currentUser.dialog = true"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-chip v-show="item.kiosk_guthaben_akutell" class="mx-2" color="green" label text-color="white">
              <v-icon left> mdi-cash </v-icon>
              {{ item.kiosk_guthaben_akutell }} €
            </v-chip>
            <v-btn elevation="0" class="mx-2" small :to="'kiosk/' + item.id"
              ><v-icon>mdi-store</v-icon></v-btn
            >
          </v-list-item-action-text>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>

<script>
import axios from 'axios';
export default {
  data: () => ({
    search: "",
    currentUser: {
      dialog: false,
      name: "",
      id: "",
      kiosk_guthaben_akutell: "",
      kiosk_guthaben_einzahlung: null
    }
  }),
  computed: {
    filteredUsers() {
      return this.$store.state.users.filter((user) => {
        return user.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    saveUser(item) {
      axios
      .patch('https://kjg-api.rezept-zettel.de/api/kiosk/user/' + item.id,
        this.currentUser)
      .then(response => {
        this.$store.state.users.find((user) => {
          return user.id == item.id;
        }).kiosk_guthaben_akutell = response.data.kiosk_guthaben_akutell;
        this.currentUser.dialog = false;
      })
      .catch(error => {
        window.alert('Fehler beim Speichern');
        console.log(error);
      });
    }
  },
};
</script>

<style>
</style>