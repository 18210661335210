<template>
  <v-app dark>
    <v-app-bar app>
      <v-toolbar-title>Space - Kiosk</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
export default {
  data: () => ({
    popup: false,
    password: null,
    loading: false,
    text: "",
    snackbar: false,
    snackbarColor: "default",
  }),
  computed: {
    /* eslint-disable */
    breakpoints() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 6;
        case "lg":
          return 6;
        case "xl":
          return 6;
      }
    },
  },
  methods: {
    routerPush(link) {
      this.$router.push(link);
    },
    onFinish() {
      if (this.password === "123456") {
        this.loading = true;
        this.snackbar = true;
        this.text = "Erfolgreich angemeldet";
        this.$router.push("/kiosk");
        setTimeout(() => {
          this.loading = false;
        }, 2000);
        setTimeout(() => {
          this.snackbar = false;
          this.$store.commit("login");
          this.popup = false;
        }, 2000);
      } else {
        this.password = null;
        this.text = "Falsches Passwort";
        this.snackbar = true;
        setTimeout(() => {
          this.snackbar = false;
        }, 2000);
      }
    },
  },
  created() {
    axios.get('https://kjg-api.rezept-zettel.de/api/kiosk/users')
    .then(response => {
      this.$store.state.users = response.data;
    })
    .catch(error => {
      console.log(error);
    });
    axios.get('https://kjg-api.rezept-zettel.de/api/kiosk/items')
    .then(response => {
      this.$store.state.kioskItems = response.data;
    })
    .catch(error => {
      console.log(error);
    });
  },
};
</script>

<style>
.position-relative {
  position: relative;
}
.v-toast {
  font-family: "poppins", sans-serif;
}
.v-navigation-drawer__content a {
  width: 100%;
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0px 20px;
  text-decoration: none;
  font-weight: bold;
  color: #555555 !important;
  transition: 0.3s ease-in;
}
.v-navigation-drawer__content a:hover {
  background-color: #efefef;
  transition: 0.3s ease-in;
}
</style>