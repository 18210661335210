<template>
  <div class="home">
    <Members></Members>
  </div>
</template>

<script>
import Members from '../components/Members.vue';
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    Members
}
}
</script>
